/* Reset some default styles */
body,
ul {
    margin: 0;
    padding: 0;
}

/* Style the header/navigation bar */
header {
    background-color: #a4384a;
    padding: 10px 0;
    text-align: center;
    top: 0%;
}

.welcome-text {
    text-align: center;
}

.welcome-text h2 {
    font-size: large;
    margin: 0;
}

.logo {
    text-align: center;
    font-size: 20px;
    color: #f1ebeb;
    font-weight: bold;
    margin: 0;
}

nav ul {
    list-style: none;
    text-align: right;
}

.dashboard-title {
    text-align: center;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0;
}

nav li {
    display: inline;
    margin-right: 20px;
}

nav a {
    text-decoration: none;
    color: #fff;
}

/* Style the icons in the navigation bar */
nav img {
    vertical-align: middle;
    margin-right: 5px;
}

/* Style circular containers */
.circle-container {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 600px;
    margin: auto;
}

.circle {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: #7d233c;
    color: #fff;
    text-align: center;
    padding: 20px;
    margin: 10px;
    top: 50%;
    display: inline-block;
    transition: background-color 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: background-color 0.3s;
    flex: 0 0 calc(50% - 40px);
}

.circle a {
    text-decoration: none;
    color: #fff;
}

.circle:hover {
    background-color: #e65585;
}


.dashboard-links ul {
    list-style: none;
    padding: 0;
}

.dashboard-links li {
    margin: 10px 0;
}

.dashboard-links a {
    text-decoration: none;
    color: #f9f6f6;
    font-weight: bold;
}

/* Style the footer */
footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.footer-links ul {
    list-style: none;
    padding: 0;
}

.footer-links li {
    display: inline;
    margin: 0 10px;
}

.footer-links a {
    text-decoration: none;
    color: #fff;
}

.chat-button {
    position: fixed;
    bottom: 40px;
    right: 20px;
    z-index: 1;
    background: #db3450;
    color: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 10px;
    font-size: 10px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.chat-button:hover {
    background: rgb(124, 40, 55);
}

/* Academic Program Overview Page Styles */

.program-overview-container {
    margin: 20px;
    font-family: Arial, sans-serif;
}

.program-overview-container h1,
.program-overview-container h2 {
    text-align: center;
    color: #a4384a;
}

.program-overview-container h1 {
    margin-bottom: 30px;
    font-weight: bold;
}

.program-overview-container section {
    background-color: #f1ebeb;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.program-overview-container h2 {
    margin-bottom: 15px;
}

.program-overview-container p {
    color: #333;
    line-height: 1.6;
    text-align: justify;
}


.back-to-dashboard {
    display: block;
    text-align: center;
    margin-top: 30px;
    padding: 10px;
    background-color: #7d233c;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}

.back-to-dashboard:hover {
    background-color: #a4384a;
}

.faq-section .faq-item {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
}

.faq-section .faq-item h3 {
    margin: 0;
    cursor: pointer;
    color: #a4384a;
}

.faq-section .faq-item p {
    margin: 0;
    display: none;
    padding: 10px 0;
}

.course-catalog {
    padding: 20px;
    background-color: #f5f5f5;
    /* Light background for the entire catalog */
    min-height: 100vh;
}

.catalog-header {
    text-align: center;
    margin-bottom: 30px;
}

.course-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Adjust based on the desired number of columns */
    gap: 20px;
    padding: 10px;
}

.course-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.course-card:hover {
    transform: scale(1.05);
}

.course-title {
    color: #333;
    margin-bottom: 10px;
}

.course-description {
    color: #666;
    font-size: 14px;
}

.course-professor {
    margin-top: 15px;
    font-weight: bold;
    color: #a4384a;
}

@media (max-width: 768px) {
    .course-list {
        grid-template-columns: 1fr;
        /* Single column layout for mobile devices */
    }
}

.course-details {
    padding: 20px;
    background-color: #f5f5f5;
    /* Light background for the entire details page */
    min-height: 100vh;
}

.details-header {
    text-align: center;
    margin-bottom: 30px;
}

.details-content section {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.details-content h2 {
    color: #333;
    margin-bottom: 10px;
}

.details-content p,
.details-content ul {
    color: #666;
    font-size: 14px;
    line-height: 1.6;
}

.details-content ul {
    list-style: none;
    padding: 0;
}

.details-content li {
    margin-bottom: 10px;
}


.course-catalog .course-link {
    text-decoration: none;
    /* Removes the underline from links */
    color: inherit;
    /* Inherits the text color from parent elements */
}

.course-card {
    /* existing styles */
    cursor: pointer;
    /* Indicates that the card is clickable */
}

.profile-card {
    background-color: #f1f1f1;
    padding: 20px;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.profile-card h3 {
    margin-top: 0;
    color: #333;
}

.profile-card p,
.profile-card ul {
    color: #666;
    line-height: 1.6;
}

.profile-card ul {
    list-style: none;
    padding: 0;
}

.profile-card li {
    margin-bottom: 5px;
}

.profile-card form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-card input {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.profile-card button {
    margin: 5px;
    padding: 8px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.profile-card button:hover {
    background-color: #45a049;
}

.course-details input,
.course-details textarea {
    width: 100%;
    padding: 8px;
    margin: 5px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.course-details textarea {
    height: 100px;
    resize: vertical;
}

.create-assessment-container {
    padding: 20px;
    max-width: 600px;
    margin: auto;
    background-color: #f5f5f5;
    /* Light background */
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.create-assessment-container h1 {
    text-align: center;
    color: #333;
}

.create-assessment-container form {
    display: flex;
    flex-direction: column;
}

.create-assessment-container label {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
}

.create-assessment-container input[type="text"],
.create-assessment-container input[type="date"],
.create-assessment-container select,
.create-assessment-container textarea {
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.create-assessment-container button {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.create-assessment-container button:hover {
    background-color: #45a049;
}

.create-exam-container {
    padding: 20px;
    max-width: 600px;
    margin: auto;
    background-color: #f5f5f5;
    /* Light background */
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.create-exam-container h1 {
    text-align: center;
    color: #333;
}

.create-exam-container form {
    display: flex;
    flex-direction: column;
}

.create-exam-container label {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
}

.create-exam-container input[type="text"],
.create-exam-container input[type="date"],
.create-exam-container select {
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.create-exam-container button {
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.create-exam-container button:hover {
    background-color: #45a049;
}

.grade-assessments-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.grade-assessments-container h1 {
    text-align: center;
    color: #333;
}

.selection-container,
.grades-container {
    margin-bottom: 20px;
}

.selection-container select {
    padding: 8px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.student-grade {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.student-grade span {
    margin-right: 10px;
}

.student-grade input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.course-details {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f5f5f5;
    /* Light background for the form */
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.course-details header {
    text-align: center;
    margin-bottom: 20px;
}

.course-details input[type="text"],
.course-details textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.course-details textarea {
    height: 100px;
    /* Adjust height as needed */
    resize: vertical;
}

.course-details .course-materials input[type="text"] {
    margin-bottom: 10px;
    /* Space between material inputs */
}

.course-details button {
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.course-details button[type="button"] {
    background-color: #337ab7;
    /* Different color for 'Add Material' button */
}

.course-details button:hover {
    opacity: 0.9;
}

/* Additional styles for sections */
.course-details section {
    margin-bottom: 20px;
}

.course-details h2 {
    color: #333;
    margin-bottom: 10px;
}

.add-program-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f5f5f5;
    /* Light background for the form */
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.add-program-container h1 {
    text-align: center;
    color: #333;
}

.add-program-container form {
    display: flex;
    flex-direction: column;
}

.add-program-container label {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
}

.add-program-container input[type="text"],
.add-program-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.add-program-container textarea {
    height: 100px;
    /* Adjust height as needed */
    resize: vertical;
}

.add-program-container button {
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.add-program-container button:hover {
    opacity: 0.9;
}

.user-list {
    margin: 20px;
}

.user-list h2 {
    text-align: center;
    color: #333;
}

.user-list table {
    width: 100%;
    border-collapse: collapse;
}

.user-list th,
.user-list td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.user-list th {
    background-color: #f4f4f4;
}

.user-list button {
    margin-right: 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.user-list button:hover {
    background-color: #ddd;
}

.manage-enrollment-container {
    padding: 20px;
    max-width: 1000px;
    margin: auto;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.manage-enrollment-container h1 {
    text-align: center;
    color: #333;
}

.manage-enrollment-container table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.manage-enrollment-container th,
.manage-enrollment-container td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.manage-enrollment-container th {
    background-color: #f4f4f4;
}

.manage-enrollment-container td select,
.manage-enrollment-container td input {
    width: 100%;
    padding: 5px;
    margin: 2px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.curriculum-development {
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
}

.content-container {
    max-width: 800px;
    margin: auto;
}

.content-container h1 {
    text-align: center;
    color: #333;
}

.content-container section {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.content-container h2 {
    color: #333;
    margin-bottom: 10px;
}

.content-container p {
    color: #666;
    line-height: 1.6;
}

.content-container form {
    margin-top: 15px;
}

.content-container button {
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.content-container button:hover {
    background-color: #45a049;
}

.policies-processes {
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
}

.content-container {
    max-width: 800px;
    margin: auto;
}

.content-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.content-container section {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.content-container h2 {
    color: #333;
    margin-bottom: 10px;
}

.content-container p {
    color: #666;
    line-height: 1.6;
}

.messages-container {
    padding: 20px;
    background-color: #f5f5f5;
    min-height: 100vh;
}

.messages-content {
    display: flex;
    max-width: 1000px;
    margin: auto;
}

.message-list {
    flex: 1;
    padding: 20px;
    background-color: #fff;
    margin-right: 20px;
    overflow-y: auto;
}

.message-summary {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.message-summary:hover {
    background-color: #f4f4f4;
}

.message-details {
    flex: 2;
    padding: 20px;
    background-color: #fff;
    overflow-y: auto;
}

.message-details h2 {
    color: #333;
}

.message-details .message-body {
    margin-top: 15px;
    color: #666;
    line-height: 1.6;
}

/* Styles for the grades section */
.grades-section {
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.grade-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.grade-card span {
    font-weight: 500;
    color: #333;
}

.grade-card select {
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
}

button {
    padding: 8px 15px;
    margin-top: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #45a049;
}

.grade-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* ... existing styles */
}

.grade-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.grade-card textarea {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    resize: vertical;
}

.exams-assignments {
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.exams-assignments h2 {
    color: #333;
    margin-bottom: 10px;
}

.exams-assignments p {
    color: #555;
    margin-bottom: 8px;
}

.exams-assignments button {
    padding: 8px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.exams-assignments button:hover {
    background-color: #45a049;
}

.exam-response {
    margin-top: 15px;
    padding: 10px;
    background-color: #e7f5e9;
    border-left: 5px solid #4CAF50;
    color: #2a662a;
    font-style: italic;
    border-radius: 5px;
}

.policies-processes .content-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.policies-processes form {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.policies-processes form input[type="text"],
.policies-processes form input[type="number"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.policies-processes form button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.policies-processes form button:hover {
    background-color: #0056b3;
}

.policies-processes .policy-item {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.policies-processes .policy-item p {
    margin: 5px 0;
}

.policies-processes .policy-item button {
    padding: 5px 10px;
    margin-right: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.policies-processes .policy-item button:hover {
    background-color: #218838;
}

.policies-processes .policy-item .delete-btn {
    background-color: #dc3545;
}

.policies-processes .policy-item .delete-btn:hover {
    background-color: #c82333;
}

.no-underline,
.no-underline:hover,
.no-underline:visited,
.no-underline:active {
    text-decoration: none;
}

/* CoursesPage.css */

.courses-page {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.courses-page h1 {
    color: #333;
    text-align: center;
}

/* CourseItem.css */

.course-item {
    border-bottom: 1px solid #ddd;
    padding: 15px;
    background-color: #fff;
}

.course-item h2 {
    margin: 0;
    color: #007bff;
}

.course-item p {
    margin: 5px 0;
}

/* EditCourseModal.css */

.edit-course-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    /* Ensure it's above other elements */
}

.edit-course-modal form {
    display: flex;
    flex-direction: column;
}

.edit-course-modal input,
.edit-course-modal textarea {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.edit-course-modal textarea {
    resize: vertical;
}

.messages-page {
    display: flex;
    height: 100vh;
}

.messages-sidebar {
    width: 30%;
    background-color: #f0f0f0;
    padding: 20px;
    overflow-y: auto;
}

.messages-list {
    margin-top: 20px;
}

.message-preview {
    background-color: white;
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.message-preview.active {
    background-color: #e9e9e9;
}

.message-preview h3 {
    margin: 0;
    color: #333;
}

.message-preview p {
    margin: 5px 0 0;
    color: #666;
}

.message-content {
    width: 70%;
    padding: 20px;
    overflow-y: auto;
}

.message-detail {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.no-message-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.message-body {
    margin-top: 15px;
    line-height: 1.5;
}

/* Add additional styling as needed */
.message-reply {
    margin-top: 20px;
}

.message-reply textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    resize: vertical;
}

.message-reply button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.message-reply button:hover {
    background-color: #0056b3;
}

.messages-page {
    display: flex;
    width: 100%;
    height: 100vh;
}

.user-list {
    width: 25%;
    height: 100%;
    background-color: #f3f3f3;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
}

.user-list h2 {
    margin-top: 0;
    color: #333;
}

.user-list ul {
    list-style: none;
    padding: 0;
}

.user-list ul li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

.user-list ul li:hover {
    background-color: #e9e9e9;
}

.message-area {
    flex-grow: 1;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
}

.message-area h2 {
    margin-top: 0;
    color: #333;
}

.message-area>div {
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.message-area>div:last-child {
    border-bottom: none;
}

strong {
    font-weight: bold;
}

.messages-page {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-list ul {
    list-style: none;
    padding: 0;
}

.user-list li {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.user-list li:hover {
    background-color: #f0f0f0;
}

.message-area {
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 20px;
}

.message {
    margin-bottom: 10px;
}

.messages {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 20px;
}

.message-area form {
    display: flex;
}

.message-area input[type="text"] {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
}

.message-area button {
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.message-area button:hover {
    background-color: #0056b3;
}

.user-list table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-top: 20px;
}

.user-list th,
.user-list td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.user-list th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.user-list td input[type="text"] {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.user-list td button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.user-list button.edit-button {
    background-color: #007bff;
    color: #fff;
}

.user-list button.delete-button {
    background-color: #dc3545;
    color: #fff;
}

.user-list button:hover {
    opacity: 0.9;
}

/* Add some spacing between table rows */
.user-list tbody tr:not(:last-child) {
    margin-bottom: 10px;
}

/* Add these styles to your existing CSS */

/* Improve table styling */
.user-list table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
    margin-top: 20px;
}

.user-list th,
.user-list td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.user-list th {
    background-color: #f4f4f4;
}

.user-list tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.user-list button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
}

.user-list button:hover {
    background-color: #0056b3;
}

/* Center table content vertically and horizontally */
.user-list th,
.user-list td,
.user-list button {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Style the input fields inside table cells */
.user-list input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Improve button spacing and styling */
.user-list button {
    margin: 0 5px;
}

.user-list button:last-child {
    background-color: #dc3545;
    /* Red color for Delete button */
}

.user-list button:last-child:hover {
    background-color: #c82333;
}

/* Existing styles ... */

/* Add these new styles for user list UI improvements */
.user-list table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
    margin-top: 20px;
}

.user-list th,
.user-list td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.user-list th {
    background-color: #f4f4f4;
}

.user-list tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.user-list button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.user-list button:hover {
    background-color: #0056b3;
}

.user-list input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.user-list button.delete {
    background-color: #dc3545;
    /* Red color for Delete button */
}

.user-list button.delete:hover {
    background-color: #c82333;
}

/* ... Additional existing styles */

.chat-app-container {
    display: flex;
    height: 100vh;
    font-family: 'Arial', sans-serif;
}

.user-list {
    width: 30%;
    overflow-y: auto;
    border-right: 1px solid #ddd;
    padding: 10px;
}

.user {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.user.active {
    background-color: #ebebeb;
}

.user-name {
    font-weight: bold;
}

.message-area {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.chat-header {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.message {
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 20px;
    max-width: 60%;
    word-wrap: break-word;
}

.message.sent {
    align-self: flex-end;
    background-color: #dcf8c6;
}

.message.received {
    align-self: flex-start;
    background-color: #fff;
}

.send-message-form {
    display: flex;
    border-top: 1px solid #ddd;
    padding-top: 10px;
}

.send-message-form input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    margin-right: 10px;
}

.send-message-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.send-message-form button:hover {
    background-color: #0056b3;
}

/* MessagesPage.css */
.messages-page {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
}

.sidebar {
    width: 30%;
    background-color: #f0f0f0;
    overflow-y: auto;
    padding: 10px;
    border-right: 1px solid #ddd;
}

.user {
    padding: 10px;
    margin: 5px 0;
    background-color: #fff;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.user:hover {
    background-color: #ebebeb;
}

.chat-area {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #e5ddd5;
}

.message {
    max-width: 60%;
    margin-bottom: 10px;
    padding: 5px 10px;
    border-radius: 8px;
    color: #000000;
}

.sent {
    background-color: #4fc3f7;
    align-self: flex-end;
}

.received {
    background-color: #34b7f1;
    align-self: flex-start;
}

.message-input {
    display: flex;
    padding: 10px;
    background-color: #f2f2f2;
    border-top: 1px solid #ddd;
}

.message-input input {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
}

.message-input button {
    padding: 10px 20px;
    background-color: #4fc3f7;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.message-input button:hover {
    background-color: #39a2db;
}

/* Global.css */
.back-button {
    background-color: #ffffff;
    color: #000000;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.back-button:hover {
    background-color: #a7a7a7;
}

.grades-report-container {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.grades-report-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    margin-top: 10px;
}

table th,
table td {
    padding: 10px;
    border: 1px solid #ddd;
}

table th {
    background-color: #8d8a8a;
    color: white;
    font-weight: bold;
}

table tr:nth-child(odd) {
    background-color: #f2f2f2;
}

table tr:hover {
    background-color: #e9ecef;
}

/* Responsive Design */
@media screen and (max-width: 600px) {
    table {
        display: block;
        overflow-x: auto;
    }
}

/* Feedback Container */
.feedback-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

/* Feedback Form */
.feedback-form {
    margin-bottom: 20px;
}

.feedback-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
    min-height: 100px;
}

.feedback-form button {
    display: block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.feedback-form button:hover {
    background-color: #0056b3;
}

/* Feedback List */
.feedback-list h2 {
    margin-top: 20px;
}

.feedback-list ul {
    list-style: none;
    padding: 0;
}

.feedback-list li {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
}

.feedback-list li strong {
    font-weight: bold;
}

/* Header Styles (if needed) */
.header {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    text-align: center;
}

/* Footer Styles (if needed) */
.footer {
    background-color: #f0f0f0;
    padding: 10px;
    text-align: center;
}